import {useState, useEffect} from 'react';
import {Container} from '@mui/material';
import Enterprise from './Enterprise';
import {post} from './api/page';
import {useNavigate, useParams} from 'react-router-dom';
import Loader from './Loader';
import Nombre from './Nombre';
import RUT from './RUT';
import {Helmet} from 'react-helmet';
import {titleCase} from '../utils/title';
import BackButton from './BackButton';

const Persona = () => {
    const [loaded, setLoaded] = useState(false);
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState(undefined);
    const {rut, name_slug} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if(!loaded) {
            setLoader(true);
            setLoaded(true);
            post(rut, name_slug).then(data => {
                if(data.status === 'success') {
                    setData(data);
                } else {
                    setData(undefined);
                    navigate('/not-found');
                }
                setLoader(false);
            });
        }
    }, [loaded, loader, navigate, rut, name_slug]);

    return (<Container maxWidth="sm">
        <Loader loader={loader} />
        {loaded && !loader && data !== undefined && (<>
        <Helmet>
            <title>{titleCase(data.nombre)} | {data.rut}</title>
            <meta name="description" content={"Razón Social "+titleCase(data.nombre)+", RUT "+data.rut} />
            <meta name="keywords" content={"rut, nombre, razon social, "+titleCase(data.nombre)+", "+data.rut} />
        </Helmet>
        <BackButton />
        <Nombre nombre={data.nombre} />
        <RUT rut={data.rut} />
        <Enterprise />
        </>)}
        </Container>);
}

export default Persona;
