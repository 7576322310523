import {Paper, Stack} from '@mui/material';
import FactoryIcon from '@mui/icons-material/Factory';

const Enterprise = () => {
    return (<Paper elevation={3} sx={{p:4, mt:2}}>
        <Stack direction="row" display="flex" alignItems="center" justifyContent="center">
            <FactoryIcon sx={{fontSize: 100, color: "#DAA520"}} />
        </Stack>
        </Paper>);
}

export default Enterprise;
